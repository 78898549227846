
import React, { useCallback, useEffect, useState, useRef } from 'react'
import MainLayout from "./MainLayout";
import iconcamera from "./../assets/images/icon-camera.svg";
import example from "./../assets/images/example-image.jpg";
import { useDropzone } from 'react-dropzone'
import { app, db } from "./../firebase";
import { useAuthState } from "react-firebase-hooks/auth";
import { getAuth, signInWithEmailAndPassword, signOut } from "firebase/auth";
import { fromImage } from 'imtool';
import PhotoSwipeLightbox from 'photoswipe/lightbox';
import { Link } from "react-router-dom";
import Switch from "react-switch";
import 'photoswipe/style.css';
import {
    doc,
    setDoc,
    Timestamp,
    collection,
    query,
    where,
    onSnapshot,
    orderBy,
    limit,
    getCountFromServer
} from "firebase/firestore";
import { uuidv4, getUserFromLocalStorage, setUserToLocalStorage } from "./../utils";

const Main = () => {

    var userLogin = getUserFromLocalStorage();

    const [fotos, setFotos] = useState([]);
    const [files, setFiles] = useState([]);
    const [only, setOnly] = useState(userLogin.onlyMyData);
    const auth = getAuth(app);
    const [user] = useAuthState(auth);
    const unsubFromMessagesRef = useRef();
    const [loading, setLoading] = useState(false);
    const [limitQuery, setLimitQuery] = useState(20);
    const [loadingPhotos, setLoadingPhotos] = useState(false);
    const [count, setCount] = useState(0);

    const onDrop = useCallback(acceptedFiles => {

        setFiles(acceptedFiles);
    }, [])
    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        onDrop, accept: {
            'image/*': ['.jpeg', '.png'],

        }
    })

    useEffect(() => {
        if (files.length > 0) {


            files.forEach(file => {
                setLoading(true);
                var reader = new FileReader();
                reader.readAsDataURL(file);
                reader.onload = function () {
                    let base64File = reader.result;
                    setLoading(true);
                    const img = new Image();
                    img.src = base64File;
                    img.onload = () => {
                        setLoading(true);
                        fromImage(base64File)
                            .then(tool => tool.thumbnail(1300, false).toDataURL())
                            .then(blob => {
                                fromImage(blob)
                                    .then(tool2 => tool2.thumbnail(300, false).toDataURL())
                                    .then(thumb => {
                                        setLoading(true);
                                        const docData = {
                                            id: uuidv4(),
                                            userId: userLogin.id,
                                            userName: userLogin.nombre,
                                            activa: true,
                                            user: "Andrés",
                                            image: blob,
                                            thumb: thumb,
                                            width: img.width,
                                            height: img.height,
                                            fecha: Timestamp.fromDate(new Date()),
                                        };
                                        setDoc(doc(db, "fotos", uuidv4()), docData);
                                        var element = document.getElementById("gallery1");
                                        element.scrollIntoView();
                                        setLoading(false);
                                        //window.scrollTo({ top: 0, behavior: 'smooth' });

                                    });


                            })
                            .catch(e => {
                                console.error(e);
                            });



                        //alert(img.width + " " + img.height);
                    }




                };

            });
            setFiles([]);
        }

    }, [files]);

    const updateCount = () => {
        const coll = collection(db, "fotos");
        getCountFromServer(coll).then((snapshot) => {
            setCount(snapshot.data().count);
        });
    }


    useEffect(() => {


        if (user) {
            const q =
                !only ?
                    query(collection(db, "fotos"), where("activa", "==", true), limit(limitQuery), orderBy("fecha", "desc"))
                    :
                    query(collection(db, "fotos"), where("activa", "==", true), where("userId", "==", userLogin.id), limit(limitQuery), orderBy("fecha", "desc"));

            const unsubFromMessages = onSnapshot(q, (snapshot) => {
                setLoadingPhotos(true);
                updateCount();
                var arr = [];
                snapshot.docs.map((doc) => {
                    arr.push(doc.data());
                });
                setFotos(arr);
                setLoadingPhotos(false);
            });
            unsubFromMessagesRef.current = unsubFromMessages;
        }

        return () => {
            unsubFromMessagesRef.current && unsubFromMessagesRef.current();
        };

    }, [user, only, limitQuery]);

    useEffect(() => {
        let lightbox = new PhotoSwipeLightbox({
            gallery: '#gallery1',
            children: 'a',
            pswpModule: () => import('photoswipe'),
        });
        lightbox.init();

        return () => {
            lightbox.destroy();
            lightbox = null;
        };
    }, []);





    return (
        <MainLayout>
            <div className="buttons">
                {
                    !loading ?
                        (
                            <>
                                <div {...getRootProps()}>
                                    <input {...getInputProps()} />
                                    {
                                        isDragActive ?
                                            <p>Drop the files here ...</p> :
                                            <p className='btn btn-primary btn-icon'><img src={iconcamera} /> Subir Fotos </p>
                                    }
                                </div>
                            </>

                        )
                        :
                        (
                            <p className='btn btn-primary btn-icon'><img src={iconcamera} /> Cargando...</p>
                        )
                }
                <div className="buttons__group">
                    <Link className="btn btn-secondary btn-mini" to={'/signature-book'}>Libro de firmas</Link>

                </div>
            </div>
            <div className="content">
                <div className="content__int">

                    <label className="onlyfotos">
                        <span>Sólo mis fotos</span>
                        <Switch onChange={() => {
                            setOnly(!only);
                            userLogin.onlyMyData = !only;
                            setUserToLocalStorage(userLogin);
                        }} checked={only} />
                    </label>

                    <h1 className="title">Albúm de fotos</h1>

                    <div className="pswp-gallery list" id={'gallery1'}>
                        {
                            loadingPhotos ?
                                (
                                    <p>Cargando...</p>
                                )
                                :
                                (
                                    <>
                                        {fotos.map((foto, index) => (
                                            <a
                                                className="list__item"
                                                href={foto.image}
                                                data-pswp-width={foto.width}
                                                data-pswp-height={foto.height}
                                                key={foto.id}
                                                target="_blank"
                                                rel="noreferrer"
                                            >
                                                <span className="user">{"@" + foto.userName} </span>

                                                <img style={{ height: '150px', width: '150px' }} src={foto.thumb} alt="" />
                                            </a>
                                        ))}
                                    </>
                                )
                        }

                    </div>



                    {/* <ul className="list">

                        {
                            fotos.map(foto => (
                                <li key={foto.id} className="list__item"><img className="" src={foto.image} /><span className="user">@lauhered</span></li>
                            ))
                        }
                        {/* <li className="list__item"><img className="" src={example} /><span className="user">@lauhered</span></li>
                        <li className="list__item"><img className="" src={example} /><span className="user">@lauhered</span></li>
                        <li className="list__item"><img className="" src={example} /><span className="user">@lauhered</span></li>
                        <li className="list__item"><img className="" src={example} /><span className="user">@lauhered</span></li>
                        <li className="list__item"><img className="" src={example} /><span className="user">@lauhered</span></li>
                        <li className="list__item"><img className="" src={example} /><span className="user">@lauhered</span></li>
                        <li className="list__item"><img className="" src={example} /><span className="user">@lauhered</span></li>
                        <li className="list__item"><img className="" src={example} /><span className="user">@lauhered</span></li> 
                    </ul> */}
                    <div className="buttons">
                        {/*<a href="#" className="btn btn-secondary btn-icon">Cargar más</a>
                        <br />
                        <a href="#" className="btn btn-primary btn-icon"><img src={iconcamera} /> Subir fotos</a>
                         <input accept="image/*" id="icon-button-file" type="file" capture="environment" /> */}

                        {
                            !loading ?
                                (
                                    <>
                                        <div {...getRootProps()}>
                                            <input {...getInputProps()} />
                                            {
                                                isDragActive ?
                                                    <p>Drop the files here ...</p> :
                                                    <p className='btn btn-primary btn-icon'><img src={iconcamera} /> Subir Fotos</p>
                                            }
                                        </div>
                                    </>

                                )
                                :
                                (
                                    <p className='btn btn-primary btn-icon'><img src={iconcamera} /> Cargando...</p>
                                )
                        }
                        {
                            (fotos.length >=limitQuery) && 
                            (
                                <p onClick={() => {
                                    if (!loading)
                                        setLimitQuery(limitQuery + 10);
                                }} className='btn btn-secondary'><img src={iconcamera} />{!loading ? "Ver más... ("+count+")" : "Cargando..."}</p>
                            )
                        }

                    
                    </div>

                </div>
            </div>

        </MainLayout>


    )
}

export default Main