import React, { useCallback, useEffect, useState, useRef } from 'react'
import { Header, Footer } from "./../components";
import iconcamera from "./../assets/images/icon-camera.svg";
import example from "./../assets/images/example-image.jpg";
import iarrow from "./../assets/images/icon-arrow.svg";
import { app, db } from "./../firebase";
import { useAuthState } from "react-firebase-hooks/auth";
import { getAuth, signInWithEmailAndPassword, signOut } from "firebase/auth";

import {
  doc,
  setDoc,
  Timestamp,
  collection,
  query,
  where,
  onSnapshot,
} from "firebase/firestore";

import { uuidv4, getUserFromLocalStorage, setUserToLocalStorage,deleteUserFromLocalStorage,getLoginCode } from "./../utils";

const MainLayout = ({ children, hidePhoto }) => {

  var userLogin = getUserFromLocalStorage();

  const auth = getAuth(app); 
   const [user, loading, error] = useAuthState(auth);

  const unsubFromMessagesRef = useRef();
  
  
  useEffect(() => {

    if (user && userLogin) {
        const q = query(collection(db, "login"))
        const unsubFromMessages = onSnapshot(q, (snapshot) => {
        
          snapshot.docs.map((doc) => {
           if(doc.data().codigo!=getLoginCode()){
            deleteUserFromLocalStorage(()=>{
              logout();
              window.location.reload();
            })
           }
        });
;         
        });
        unsubFromMessagesRef.current = unsubFromMessages;
    }

    return () => {
      
        unsubFromMessagesRef.current && unsubFromMessagesRef.current();
    };

}, [user]);

const logInWithEmailAndPassword = async (email, password) => {
  try {
    await signInWithEmailAndPassword(auth, email, password);
   
  
  } catch (err) {
    console.error(err);
   
  }
};

const logout = () => {
  signOut(auth);
};

useEffect(() => {
 
  if (loading) return;
  if (!user) {
    
    logInWithEmailAndPassword(
      "enlaceinmayjonny@gmail.com",
      "1nmayjonny1$3nl4c3"
    );
  }

}, [user]);

const getClass = () => {
  var css="wrapper";
  if(hidePhoto){
    css+=" login";
  }
  return css;
}

  return (
    <div className={getClass()}>
      <Header hidePhoto={hidePhoto} logout={logout} />
      {children}
      <Footer />
      <a href="#" className="top-button"><img src={iarrow} /></a>
    </div>
  );
}

export default MainLayout;