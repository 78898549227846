import { useEffect, useState, useRef, lazy, Suspense } from "react";
import logo from "./logo.svg";
import { useAuthState } from "react-firebase-hooks/auth";
import "./App.css";
import { getAuth, signInWithEmailAndPassword, signOut } from "firebase/auth";
import { app, db } from "./firebase";

//import MainLayout from "./pages/MainLayout";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import RouteGuard from "./components/RouteGuard";

import {
  doc,
  setDoc,
  Timestamp,
  collection,
  query,
  where,
  onSnapshot,
} from "firebase/firestore";
import { uuidv4 } from "./utils";
import Main from "./pages/Main";
import { getUserFromLocalStorage, deleteUserFromLocalStorage } from "./utils";

function App() {
  const auth = getAuth(app);
  const [user, loading, error] = useAuthState(auth);
  const unsubFromMessagesRef = useRef();
  var userLogin = getUserFromLocalStorage();

  const Login = lazy(() => import("./pages/Login"));
  const Main = lazy(() => import("./pages/Main"));
  const Album = lazy(() => import("./pages/Album"));
  const SignatureBook = lazy(() => import("./pages/SignatureBook"));






  const SuspenseLoading = () => {
    return (
      <div>
        <h1>Cargando...</h1>
      </div>
    );
  };

  return (

    <Suspense fallback={<SuspenseLoading />}>
      <BrowserRouter>
        <Routes>
          <Route
            path="/"
            element={
              <RouteGuard>
                <Main />
              </RouteGuard>
            }
          />
          <Route
            path="/login"
            element={

              <Login />

            }
          />

          <Route
            path="/signature-book"
            element={
              <RouteGuard>
                <SignatureBook />
              </RouteGuard>
            }
          />
        </Routes>
      </BrowserRouter>

    </Suspense>


  );
}

export default App;
