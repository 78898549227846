export const uuidv4 = () => {
  return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, c =>
    (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
  );
}


export const getUserFromLocalStorage = () => {
  return localStorage.getItem('user_boda') ? JSON.parse(localStorage.getItem('user_boda')) : null
}
export const setUserToLocalStorage = (user) => {
  localStorage.setItem('user_boda', JSON.stringify(user))
}
export const deleteUserFromLocalStorage = (handle) => {
  localStorage.removeItem('user_boda')
  if(handle){
    handle();
  }
}
export const setOnlyMyDataToLocalStorage = (only) => {
  var user = getUserFromLocalStorage()
  if (user) {
    user.onlyMyData = only;
    setUserToLocalStorage(user);
    return user;
  }
  return null;


}
export const getLoginCode=()=>{
  return "bdj23".toLowerCase();
}
