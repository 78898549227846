
import imageheader from "./../assets/images/image-header.png";
import headermobile from "./../assets/images/header-mobile.svg";
import {deleteUserFromLocalStorage} from "./../utils";

const Header = ({ hidePhoto,logout }) => {

  return (
    <header className="header">
       {
        !hidePhoto &&
        (
          <a href="#" onClick={()=>{
            deleteUserFromLocalStorage(()=>{
              if(window.confirm("¿Estás seguro de cerrar sesión?")){
                logout();
                window.location.href = "/";
              }
            
            })
    
          }} className="btn btn-secondary btn-minitop btn-icon" >Cerrar sesión</a>
        )

       }     


      <img className="header__bg" src={headermobile} />
     
      <img className="header__image" src={imageheader} />
      
     

    </header>
  )
}

export default Header;